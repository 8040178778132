import React from 'react';

export default function DisabledPage(props) {

  const DisabledMessage = "Welcome to ektiwari.com domain name";

  return (
    <div>
    {DisabledMessage}
    </div>
  );
}
