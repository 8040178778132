import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LockIcon from '@material-ui/icons/Lock';
import ListIcon from '@material-ui/icons/List';
import PeopleIcon from '@material-ui/icons/People';
import { Link } from "@reach/router";
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import WorkIcon from '@material-ui/icons/Work';
import GroupIcon from '@material-ui/icons/Group';

function ListItemLink(props) {
  const { icon, primary, to, selected, setActiveLoc } = props;
  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={to} {...linkProps} onClick={()=>setActiveLoc(to)}
        />
      )),
    [to, setActiveLoc],
  );

  return (
    <li>
      <ListItem button component={CustomLink} selected={selected}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

export const MainListItems = (props) =>{
  const [activeLoc, setActiveLoc] = React.useState(window.location.pathname);
  return (
  <div>

    <ListItemLink to="/" primary="Model Version 2.1" icon={<DashboardIcon />} 
    selected={ activeLoc ==='/' ? true:false} setActiveLoc={setActiveLoc}
    />

    <ListItemLink to="/customers" primary="Customers" icon={<PeopleIcon />} 
    selected={ activeLoc ==='/customers' ? true:false} setActiveLoc={setActiveLoc}
    />
   
    <ListItem button
    onClick={()=>props.updateUserInformation({})}>
      <ListItemIcon>
        <LockIcon />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItem>
  </div>
);
}