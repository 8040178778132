import React from 'react';
import {useState} from 'react';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from "@material-ui/core";
import Dashboard from './views/DashboardView';
import DisabledPage from './views/DisabledPage'
import './App.css';

import SignInView from "./views/SignInSide";


function App() {
  const defaultTheme = createTheme({direction: 'ltr', palette: {
    type: "light",
    primary: {
      light: "#5C755E",
      main: "#5C755E",
      dark: "#004d40"
    }
  }})
  const [currentTheme, setCurrentTheme] = useState(defaultTheme);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('@user')) || {}
  );

  const [enabled, setEnabled] = useState(true);

  const updateUserInformation = (user) =>{
    setUser(user);
    localStorage.setItem("@user", JSON.stringify(user));
  }

  React.useEffect(() => {
  },[]);

  return (
    <div>

    {!user.token && enabled &&
    <ThemeProvider theme={currentTheme}>
      <SignInView updateUserInformation={updateUserInformation} />
    </ThemeProvider>
    }

    {user.token && enabled &&
    <ThemeProvider theme={currentTheme}>
      <Dashboard updateUserInformation={updateUserInformation} user={user} />
    </ThemeProvider>
    }

    {!enabled &&
      <DisabledPage />
    }

    </div>
  );
}

export default App;