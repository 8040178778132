import React from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import { fade } from '@material-ui/core/styles/colorManipulator';
import data from '../config/data';
import Input from '@material-ui/core/Input';
import {
    Typography,
    Paper,
    Link,
    Grid,
    Button,
    CssBaseline,
    RadioGroup,
    FormLabel,
    MenuItem,
    FormGroup,
    FormControl,
    FormControlLabel,
    InputBase,
    Select,
    Box,
    CircularProgress
} from '@material-ui/core';
import moment, { isDate } from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(0),
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(0),
    },
    checkboxGrid: {
      border: `${fade(theme.palette.text.primary, 0.2)} 1px solid` ,
      marginTop: "5px",
      padding: "10px",
    }
  }));

const CustomerView = (props) => {
  const classes = useStyles();  
  const loading = props.loading;
  const [customer, setCustomer] = React.useState(props.selectedCustomer);  

  const editCustomerVisible = props.editCustomerVisible;
  const setEditCustomerVisible = props.setEditCustomerVisible;
  const newCustomerVisible = props.newCustomerVisible;
  const setNewCustomerVisible = props.setNewCustomerVisible;
  const addNewCustomer = props.addNewCustomer;
  const editCustomer = props.editCustomer;

  const handleAddCustomer = (e) => {
    addNewCustomer(customer);
  }

  const handleSaveCustomer = (e) =>{
    editCustomer(customer);
  }

  const handleCancelCustomer = (e) =>{
    editCustomerVisible?setEditCustomerVisible(false):setNewCustomerVisible(false);
  }

  const handleTextChange = (e, fieldname) =>{
    setCustomer({...customer, [fieldname]: e.target.value.trim()});
  }
    
    return (
        <div>
          <Typography 
            component="h2" 
            variant="h6" 
            color="primary" 
            gutterBottom>
              {editCustomerVisible?'Edit ': 'Add '} Customer
          </Typography>
            <Grid container spacing={3}> 
              <Grid item xs={6}>  
                  <TextField 
                    autoFocus
                    required
                    defaultValue= {editCustomerVisible? customer.name: ""}
                    onChange={(e) => handleTextChange(e, 'name')}
                    id="customername" 
                    label="Customer Name" 
                    variant="outlined" 
                    fullWidth>
                  </TextField>
              </Grid>
              <Grid item xs={6}>  
                  <TextField
                    required 
                    defaultValue= {editCustomerVisible? customer.email: ""}
                    onChange={(e) => handleTextChange(e, 'email')}
                    id="email" 
                    label="Customer Email" 
                    variant="outlined" 
                    fullWidth>
                  </TextField>
              </Grid>
              
            </Grid>

            <Grid container spacing={3}> 
              <Grid item xs={6}>  
                  <TextField 
                    defaultValue= {editCustomerVisible? customer.mobile: ""}
                    onChange={(e) => handleTextChange(e, 'mobile')}  
                    id="mobile" 
                    label="Customer Mobile" 
                    variant="outlined" 
                    fullWidth>
                  </TextField>
              </Grid>
              <Grid item xs={6}>
                  <TextField 
                    defaultValue= {editCustomerVisible? customer.industry: ""}
                    onChange={(e) => handleTextChange(e, 'industry')}  
                    id="industry" 
                    label="Customer Industry" 
                    variant="outlined" 
                    fullWidth>
                  </TextField>
              </Grid>
            </Grid>        

            <Grid container spacing={3}> 
              <Grid item xs={6}>  
                  <TextField 
                  defaultValue= {editCustomerVisible? customer.number_of_employees: ""}
                  onChange={(e) => handleTextChange(e, 'number_of_employees')} 
                  id="numberofemployees" 
                  label="Number of Employees" 
                  variant="outlined"
                  fullWidth 
                  helperText={''}> 
                  </TextField>
              </Grid>
              <Grid item xs={6}>  
                  <TextField
                  defaultValue= {editCustomerVisible? customer.owner: ""}
                  onChange={(e) => handleTextChange(e, 'owner')} 
                  id="owner" 
                  label="Company Owner" 
                  variant="outlined" 
                  fullWidth 
                  helperText={''}>
                  </TextField>
              </Grid>
            </Grid>
            {loading?
            <Grid container spacing={3} style={{marginTop: 10}}                    
              direction="row"
              justify="flex-end"
              alignItems="center">
                <Grid item>
                  <CircularProgress />
                </Grid>
                
            </Grid>
            :
            <Grid container spacing={3} style={{marginTop: 10}}                    
              direction="row"
              justify="flex-end"
              alignItems="center">
                <Grid item>
                  <Button onClick={(e)=>handleCancelCustomer(e)}>
                  Cancel
                  </Button>
                </Grid>

                <Grid item>
                  <Button variant="contained" color="primary" onClick={(e)=>editCustomerVisible?handleSaveCustomer(e):handleAddCustomer(e)}> 
                    {editCustomerVisible?'Save ': 'Add '} Customer
                  </Button>
                </Grid>
            </Grid>
            }
            </div>
        )
};
export default CustomerView;