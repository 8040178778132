import axois from 'axios'

const apiUri = "https://api.ektiwari.com:8443";

export const ApiFunctions = {
    getUserData: (accessCode) =>{
        return new Promise ((resolve, reject) =>{
            axois.post(apiUri+"/authenticate", {access_code: accessCode})
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            });
        });
    },
    getProjects: (user) =>{
        return new Promise ((resolve, reject) =>{
            axois.get(apiUri+"/api/projects", 
            {   headers: {
                'x-access-token': user.token
                }
            })
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            })
        })
    },
    addProject: (project, user) =>{
        return new Promise ((resolve, reject) =>{
           /*  setTimeout(()=>resolve(), 3000); */
            axois.post(apiUri+"/api/projects", {state: project},
            {   headers: {
                'x-access-token': user.token
                }
            })
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            }) 
        })
    },
    renameProject: (project) => {
        return new Promise ((resolve, reject) =>{
            resolve();
            /* axois.post("https://cloudibs.com:8180/projects/"+project._id, {state: project})
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            }); */
        });
    },
    addAnalysis: (project, analysis, user) =>{
        return new Promise ((resolve, reject) =>{
             axois.post(apiUri+"/api/projects/"+project._id+"/analysis", {state: analysis},
             {   headers: {
                 'x-access-token': user.token
                 }
             })
             .then((response)=>{
                 resolve(response.data);
             }).catch((error)=>{
                 reject(error);
             }) 
         })
    },
    uploadAnalysis: (formData, user, _uploadProgress) => {
        return new Promise ((resolve, reject) =>{
            axois.post(apiUri+"/api/uploadAnalysis", 
            formData,
            {
                headers: {
                'content-type': 'multipart/form-data',
                'x-access-token': user.token
                },
                onUploadProgress: _uploadProgress
            })
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            });
        });
    },
    removeAnalysis: (project, analysis, user) => {
        return new Promise ((resolve, reject) =>{
            axois.delete(apiUri+"/api/projects/"+project._id+"/analysis/"+analysis._id, 
            {   headers: {
                'x-access-token': user.token
                }
            })
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            });
        });
    },
    runAnalysis: (project, _analysisid, user) => {
        return new Promise ((resolve, reject) =>{
            //resolve();
            axois.post(apiUri+"/api/runAnalysis", {_projectid:project._id, _analysisid: _analysisid},
            {   headers: {
                'x-access-token': user.token
                }
            })
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            });
        });
    },
    runProject: (project, user) => {
        return new Promise ((resolve, reject) =>{
            resolve();
            axois.post(apiUri+"/api/runProject", {_projectid:project._id},
            {   headers: {
                'x-access-token': user.token
                }
            })
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            });
        });
    },
    downloadTimelines: (project, _analysisid, user)=>{
        return new Promise ((resolve, reject) =>{
            //resolve();
            axois.post(apiUri+"/api/getAnalysisTimelines", {_projectid:project._id, _analysisid: _analysisid},
            {   headers: {
                'x-access-token': user.token
                }
            })
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            });
        });
    },
    downloadInvoice: (invoicehtml)=>{
        //call the pdf service
        let params = new URLSearchParams();
        params.append('invoicehtml', invoicehtml);
        return new Promise((resolve, reject) => {
            axois.post("https://cloudibs.com:8989/Jpdf-0.0.1-SNAPSHOT/pdfservice", params,{responseType: "blob", headers: { "Content-Type": "application/x-www-form-urlencoded" }}).then((response)=>{
            resolve(response.data);
          }).catch((e)=>{
            reject(e);
          });
        });
    },
    getCustomers: (user) => {
        return new Promise ((resolve, reject) =>{
            axois.get(apiUri+"/api/customers", 
            {   headers: {
                'x-access-token': user.token
                }
            })
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            })
        })
    },
    addCustomer: (customer, user)=>{
        return new Promise ((resolve, reject) =>{
            axois.post(apiUri+"/api/customers", {state: customer},
            {   headers: {
                'x-access-token': user.token
                }
            })
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            });
        });
    },
    editCustomer: (customer, user)=>{
        return new Promise ((resolve, reject) =>{
            axois.post(apiUri+"/api/customers/"+customer._id, {state: customer},
            {   headers: {
                'x-access-token': user.token
                }
            })
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            });
        });
    },
    getCustomerInvoices: (customer, user) => {
        return new Promise ((resolve, reject) =>{
            axois.get(apiUri+"/api/invoices/"+customer._id, 
            {   headers: {
                'x-access-token': user.token
                }
            })
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                console.log(error);
                reject(error);
            })
        })
    },
    addCustomerInvoice: (customer, invoice, user)=>{
        return new Promise ((resolve, reject) =>{
            axois.post(apiUri+"/api/invoices/"+customer._id, {state: invoice},
            {   headers: {
                'x-access-token': user.token
                }
            })
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            });
        });
    },
    editCustomerInvoice: (customer, invoice, user)=>{
        console.log(invoice);
        return new Promise ((resolve, reject) =>{
            axois.post(apiUri+"/api/invoices/"+customer._id+"/"+invoice._id, {state: invoice},
            {   headers: {
                'x-access-token': user.token
                }
            })
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            });
        });
    }
}