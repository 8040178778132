import React from 'react';
import { useState, useEffect, Fragment } from 'react';
import data from '../config/data';
//import CustomerInvoiceView from './CustomerInvoiceView';
import FloatingActionButton from './FAB';

import { 
    Grid, 
    Container, 
    LinearProgress, 
    CircularProgress,
    Tooltip, 
    Button, 
    IconButton, 
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Card,
    CardContent,
    Select,
    MenuItem,
} from '@material-ui/core';

import {Edit as EditIcon, Close as CloseIcon, ArrowBackIos as ArrowBackIosIcon} from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';

import { ApiFunctions } from '../config/api';

import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import QRCode from 'qrcode.react';
import ReactDOMServer from 'react-dom/server';
import FileDownload from 'js-file-download';

var JSZip = require('jszip');
var zip = JSZip();


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      marginTop:theme.spacing(3)
    },
    fixedHeight: {
      height: 240,
    },
}));

function getDateOnly(date){
    if(!date) return;
    let dateArr = date.toString().split("T");
    return dateArr?dateArr[0]:date.toString();
}

const CustomerInvoicesView = (props) => {
    const classes = useStyles();
    const [selectedInvoice, setSelectedInvoice] = React.useState(null);
    const [newInvoiceVisible, setNewInvoiceVisible] = React.useState(false);
    const [editInvoiceVisible, setEditInvoiceVisible] = React.useState(false);
    const [invoices, setInvoices] = React.useState([]);
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState('');
    const [refresh, setRefresh] = React.useState(new Date());
    const customer = props.customer;
    const setCustomerInvoicesVisible = props.setCustomerInvoicesVisible;
    React.useEffect(()=>{
        console.log("refreshing invoices");
        setLoading(true);
        ApiFunctions.getCustomerInvoices(customer, props.user).then((invoices)=>{
            console.log(invoices);
            setInvoices(invoices);
        }).
        catch(
            (e)=>{
                console.log(e);
                setError('Your session might have expired. Please logout and log back in.');
            }
        ).finally(()=>{
            setLoading(false);
        });
    }, [refresh]);

    const addNewInvoice = (newInvoice) =>{
        setLoading(true);
        ApiFunctions.addCustomerInvoice(customer, newInvoice, props.user).then((res)=>{
            let addedInvoice = {...newInvoice, _id: res._id, number: res.number, generatedOn: res.generatedOn};
            let tempArr = [...invoices];
            tempArr.push(addedInvoice);
            setInvoices(tempArr);
        })
        .catch((e)=>setError('Your session might have expired or an error occured. Try to logout and log back in.'))
        .finally(()=>{
            setLoading(false); 
            setNewInvoiceVisible(false);
        });
    }

    const editInvoice = (invoice) =>{
        setLoading(true);
        ApiFunctions.editCustomerInvoice(customer, invoice, props.user).then((res)=>{
            let tempArr = [...invoices];
            let foundIndex = tempArr.findIndex(c=>c._id===invoice._id);
            tempArr[foundIndex] = invoice;
            setInvoices(tempArr);
        })
        .catch((e)=>setError('Your session might have expired or an error occured. Try to logout and log back in.'))
        .finally(()=>{
            setLoading(false); 
            setEditInvoiceVisible(false);
        });
    }

    const handleAddInvoiceClick = (e) => {
        newInvoiceVisible ? setNewInvoiceVisible(false) : setNewInvoiceVisible(true);
    }

    const handleEditInvoiceClick = (e, invoice) => {
        setEditInvoiceVisible(true);
        setSelectedInvoice(invoice);
    }
   
    return (
            
                (editInvoiceVisible ?
                <CustomerInvoiceView
                    customer={customer}
                    loading={loading}
                    editInvoiceVisible={editInvoiceVisible}
                    setEditInvoiceVisible={setEditInvoiceVisible}
                    selectedInvoice={selectedInvoice}
                    editInvoice={editInvoice}
                />
                :
                <React.Fragment>
                    
                    <Grid 
                    container 
                    spacing={3}
                    
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                        <Tooltip 
                            title="Back" 
                            aria-label="Back"
                            >
                            <IconButton  
                                variant="contained" 
                                color="primary" 
                                onClick={(e) => setCustomerInvoicesVisible (false)}>
                                <ArrowBackIosIcon />
                            </IconButton>
                        </Tooltip>
                        <Grid item>
                            <Typography component="h2" variant="h6" color="primary" >{customer.name}'s Posted Invoices</Typography>
                        </Grid>
                        <Grid item>
                            <FloatingActionButton
                                variant="contained"
                                color="primary"
                                clickHandler={handleAddInvoiceClick} />
                        </Grid>
                    </Grid>
                    {newInvoiceVisible && 
                    <CustomerInvoiceView 
                        customer={customer}
                        loading={loading}
                        newInvoiceVisible={newInvoiceVisible}
                        addNewInvoice={addNewInvoice}
                        setNewInvoiceVisible={setNewInvoiceVisible}
                        />
                    }
                    <Grid item xs={12}>
                    <Paper className={classes.paper}>
                    {loading&&<LinearProgress />}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><b>ID</b></TableCell>
                                <TableCell><b>Issue Date</b></TableCell>
                                <TableCell><b>Amount</b> </TableCell>
                                <TableCell><b>Paid Amount</b></TableCell>
                                <TableCell><b>Action</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoices && invoices.map((invoice, key) => (
                                <TableRow key={key}>
                                    <TableCell>{invoice._id}</TableCell>
                                    <TableCell>{getDateOnly(invoice.issuedOn)}</TableCell>
                                    <TableCell>{invoice.currency} {invoice.total_amount}</TableCell>
                                    <TableCell>{invoice.currency} {invoice.paid_amount}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Edit Invoice" aria-label="edit">
                                            <IconButton variant="contained" color="primary" onClick={
                                                (e) => handleEditInvoiceClick(e, invoice)}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    </Paper>
                    </Grid>
                </React.Fragment>
            
    ))
};

const CustomerInvoiceView = (props) =>{
    const classes = useStyles();
    const [invoice, setInvoice] = useState(props.selectedInvoice?props.selectedInvoice:{});
    const [currentLineItem, setCurrentLineItem] = useState({});
    const customer=props.customer;
    const loading = props.loading;
    
    const editInvoiceVisible = props.editInvoiceVisible;
    const setEditInvoiceVisible = props.setEditInvoiceVisible;
    const selectedInvoice = props.selectedInvoice;
    const editInvoice = props.editInvoice;
    
    const newInvoiceVisible = props.newInvoiceVisible;
    const setNewInvoiceVisible = props.setNewInvoiceVisible;
    const addNewInvoice = props.addNewInvoice;

    function roundAmount(amount){
      let frac = 100;
      return Math.round((amount+Number.EPSILON)*frac) / frac;
    }
    const handleTextInput = (e, fieldname) =>{
      setInvoice({...invoice, [fieldname]: e.target.value.trim()});
    }

    const handleNumberInput = (e,fieldname)=>{
      setInvoice({...invoice, [fieldname]: roundAmount(parseFloat(e.target.value.trim()))});
    }

    const handleLineItemAdd = () =>{
      let tempInvoice = {...invoice};
      let lineitem = {...currentLineItem};
      tempInvoice.line_items?tempInvoice.line_items.push(lineitem):(tempInvoice.line_items =[lineitem]);
      setInvoice(tempInvoice);
      updateInvoiceTotal(tempInvoice);
    }
    const handleLineItemPop = () =>{
      let tempInvoice = {...invoice};
      tempInvoice.line_items&&tempInvoice.line_items.length>0&&tempInvoice.line_items.pop();
      setInvoice(tempInvoice);
      updateInvoiceTotal(tempInvoice);
    }
    const handleLiTextInput = (e, fieldname) =>{
      setCurrentLineItem({...currentLineItem, [fieldname]: e.target.value.trim()});
    }
    const handleLiNumberInput = (e,fieldname)=>{
      setCurrentLineItem({...currentLineItem, [fieldname]: roundAmount(parseFloat(e.target.value.trim()))});
    }
  
    const updateInvoiceTotal = (tempInvoice) =>{
      let total = 0;
      tempInvoice.line_items&&tempInvoice.line_items.map(item=>{
        total+=(item.NetAmount)?item.NetAmount:0;
      });
      tempInvoice.total_amount = roundAmount(total);
      tempInvoice.QRCode = getTlvBuffer(tempInvoice);
      setInvoice(tempInvoice);
    }

    const handleAddInvoice = () =>{
        console.log(invoice);
        addNewInvoice(invoice);
    }

    const handleSaveInvoice = () =>{
        console.log(invoice);
        editInvoice(invoice);
    }
    
    const handleCancelInvoice = () =>{
        editInvoiceVisible?setEditInvoiceVisible(false):setNewInvoiceVisible(false);
    }

    useEffect(()=>{
      const totalAmount = currentLineItem.Amount?roundAmount((currentLineItem.Amount - (currentLineItem.Discount || 0))):0;
      setCurrentLineItem({...currentLineItem, ['NetAmount']: totalAmount});
    }, [currentLineItem.Amount, currentLineItem.Discount]);

    useEffect(()=>{
         editInvoiceVisible&&updateInvoiceTotal(invoice);
      }, [editInvoiceVisible]);
    
  
    const companyName = "Cacti Software W.L.L";
    const vatNumber = "1234567777";
  
    function getTlvBuffer(invoice){
      //base64 string of tlv - hex tag + hex length + string value - SellerName(1) - SellerVatNumber(2) - InvoiceTimeStamp(3) - InvoiceTotalWithVat(4) - VatTotal(5) - XMLHash(6) - 7 and 8 crypto",
      
      const invoiceTimeStamp = new Date(invoice.issuedOn);
      let tlvs = [
          Buffer.from(('0000'+(1..toString(16))).slice(-4), "hex"),
          Buffer.from(('0000'+(companyName.length.toString(16))).slice(-4), "hex"),
          Buffer.from(companyName),
          Buffer.from(('0000'+(2..toString(16))).slice(-4), "hex"),
          Buffer.from(('0000'+(vatNumber.length.toString(16))).slice(-4), "hex"),
          Buffer.from(vatNumber),
          Buffer.from(('0000'+(3..toString(16))).slice(-4), "hex"),
          Buffer.from(('0000'+(invoiceTimeStamp.toString().length.toString(16))).slice(-4), "hex"),
          Buffer.from(invoiceTimeStamp.toString()),
          Buffer.from(('0000'+(4..toString(16))).slice(-4), "hex"),
          Buffer.from(('0000'+(invoice.total_amount.toString().length.toString(16))).slice(-4), "hex"),
          Buffer.from(invoice.total_amount.toString()),
          Buffer.from(('0000'+(5..toString(16))).slice(-4), "hex"),
          Buffer.from(('0000'+(0..toString(16))).slice(-4), "hex"),
          Buffer.from(0..toString())
          /* Buffer.from(invoice.VatCategoryTaxAmount.TaxAmount.length.toString(16), "hex"),
          Buffer.from(invoice.VatCategoryTaxAmount.TaxAmount) */
      ];
      return Buffer.concat([...tlvs]);
  
    }
  
    return(
      <Fragment>
        
      <Container maxWidth="xl" className={classes.container}>
  
      <Typography component="h2" variant="h6" color="primary" gutterBottom>Invoice Information</Typography>
      <Grid container spacing={3}>
        <Grid item xs={6} lg={4} xl={3}>
          <TextField
            defaultValue={invoice&&getDateOnly(invoice.issuedOn)}
            onChange={(e) => handleTextInput(e, 'issuedOn')}  
            id="outlined-basic" 
            label="Invoice Date" 
            variant="outlined" 
            fullWidth>
            </TextField>
        </Grid>
        <Grid item xs={6} lg={4} xl={3}>
          <TextField
            defaultValue={invoice&&invoice.currency}
            onChange={(e) => handleTextInput(e, 'currency')}  
            id="outlined-basic" 
            label="Invoice Currency" 
            variant="outlined" 
            fullWidth>
            </TextField>
        </Grid>
        <Grid item xs={6} lg={4} xl={3}>
          <TextField
            defaultValue={invoice&&invoice.paid_amount}
            onChange={(e) => handleNumberInput(e, 'paid_amount')}  
            id="outlined-basic" 
            label="Paid Amount" 
            variant="outlined" 
            fullWidth>
            </TextField>
        </Grid>
      </Grid>
      <Typography style={{marginTop: 10}} component="h2" variant="h6" color="primary" gutterBottom>Add Line Item</Typography>
      <Grid container spacing={3}>
        <Grid item xs={4} lg={3} xl={2}>
          <TextField
            onChange={(e) => handleLiTextInput(e, 'Name')}  
            id="outlined-basic" 
            label="Item Name" 
            variant="outlined" 
            fullWidth>
            </TextField>
        </Grid>
        <Grid item xs={4} lg={3} xl={2}>
          <TextField
            onChange={(e) => handleLiNumberInput(e, 'Amount')}  
            id="outlined-basic" 
            label="Amount" 
            variant="outlined" 
            fullWidth>
            </TextField>
        </Grid>
        <Grid item xs={4} lg={3} xl={2}>
          <TextField
            onChange={(e) => handleLiNumberInput(e, 'Discount')}  
            id="outlined-basic" 
            label="Discount" 
            variant="outlined" 
            fullWidth>
            </TextField>
        </Grid>
        <Grid item xs={4} lg={3} xl={2}>
          <TextField
            value={currentLineItem.NetAmount || 0}
            onChange={(e) => handleLiNumberInput(e, 'NetAmount')}  
            disabled
            id="outlined-basic" 
            label="Net Amount" 
            variant="outlined" 
            fullWidth>
            </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item>
          <Button onClick={()=>handleLineItemAdd()}>Add Line Item</Button>
        </Grid>
        <Grid item>
          <Button onClick={()=>handleLineItemPop()}>Pop Line Item</Button>
        </Grid>
        
      </Grid>
        <OfficialInvoiceView invoice={invoice} customer={customer} editInvoiceVisible={editInvoiceVisible} />
        {loading?
            <Grid container spacing={3} style={{marginTop: 10}}                    
              direction="row"
              justify="flex-end"
              alignItems="center">
                <Grid item>
                  <CircularProgress />
                </Grid>
                
            </Grid>
            :
            <Grid container spacing={3} style={{marginTop: 10}}                    
              direction="row"
              justify="flex-end"
              alignItems="center">
                <Grid item>
                  <Button onClick={(e)=>handleCancelInvoice(e)}>
                  Cancel
                  </Button>
                </Grid>

                <Grid item>
                  <Button variant="contained" color="primary" onClick={(e)=>editInvoiceVisible?handleSaveInvoice(e):handleAddInvoice(e)}> 
                    {editInvoiceVisible?'Save ': 'Add '} Invoice
                  </Button>
                </Grid>
            </Grid>
            }
      </Container>
      </Fragment>
      
    )
}
  
const OfficialInvoiceView = (props) =>{  

    const invoice = props.invoice;
    const customer = props.customer;
    const editInvoiceVisible = props.editInvoiceVisible;
    
    const isItAllArabic =s=>(!/[^\u0600-\u06FF ]/.test(s));
    
    function getInternationalFormat(amount){
        return Intl.NumberFormat().format(amount);
    }

    const CompanyInformation = () =>{
        return (
        <div>
            Cacti Software W.L.L<br />
            CR 149570-1<br />
            Office 51 Bldg 1301 Rd. 4026 Blk. 340<br />
            Tel +973 3929 0099 <br />
            Manama, Kingdom of Bahrain
        </div>
        )
    }

    const SisterCompanyInformation = () =>{
        return (
        <div>
            Hasan Almehdar Establishment<br />
            CR 4030056945<br />
            10 Al Murjan Building - Sari Road<br />
            Tel +966 560 388 409 <br />
            Jeddah, KSA
        </div>
        )
    }

    const CompanyBankInformation = () =>{
        return (
            <div>
                Bank Transfer Information:<br /> <br />
                <b>Company IBAN (Bahrain):</b><br />
                Cacti Software WLL<br />
                Bank: Bahrain Islamic Bank<br />
                IBAN: BH12BIBB00100000853623<br />
                Swift: BIBBBHBM<br /><br />

                {/* <b>Company IBAN (KSA):</b><br />
                Hassan Almihdar Marketing Services Est.<br />
                CR No.: 7007667897<br />
                Bank: Arab National Bank (ANB)<br />
                IBAN: SA0430400108095449310019 */}
            </div>
        )
    }

    const SisterCompanyBankInformation = () =>{
        return (
            <div>
                Bank Transfer Information<br /> <br />
                Hassan Almihdar Marketing Services Est.<br />
                CR No.: 4030056945<br />
                Bank: Arab National Bank (ANB)<br />
                IBAN: SA0430400108095449310019
            </div>
        )
    }

    const downloadInvoicePdf = () => {
        const canvas = document.getElementById("invoiceQRCode");
        const dataUrl = canvas.toDataURL("img/png");
        let htmlInvoice = ReactDOMServer.renderToString(<HtmlComponent qrCode={dataUrl} />);
        ApiFunctions.downloadInvoice(htmlInvoice).then((res)=>{
        FileDownload(res,invoice._id+"-"+invoice.issuedOn+".pdf");
        }).catch((e)=>{
        console.log(e);
        });
    }

    const downloadInvoicePdfSister = () => {
        const canvas = document.getElementById("invoiceQRCode");
        const dataUrl = canvas.toDataURL("img/png");
        let htmlInvoice = ReactDOMServer.renderToString(<HtmlComponent qrCode={dataUrl} isSister={true} />);
        ApiFunctions.downloadInvoice(htmlInvoice).then((res)=>{
        FileDownload(res,invoice._id+"-"+invoice.issuedOn+".pdf");
        }).catch((e)=>{
        console.log(e);
        });
    }

    const HtmlComponent = (props) =>{
        const qrCode = props.qrCode;
        const isSister = props.isSister;
        const invoiceContainer = {
            marginTop: "20pt",
        }
        const pullLeft = {
            textAlign: 'left'
        }
        return (
        <html>
            <head>
            <title>E-Invoice</title>
            </head>
            <body style={{fontFamily: "Roboto",/*  fontSize: "12pt" */}}>
            <div style={invoiceContainer}>
                <table style={{marginBottom: '40px', width: '100%'}}>
                <tr>
                    <td width="50%"><img src="https://cacticonsult.com/assets/images/logo-software.png" style={{maxWidth: "200px"}} /></td>
                    <td width="50%" style={{textAlign: 'right'}}>
                    {isSister? <SisterCompanyInformation /> : <CompanyInformation /> }
                    </td>
                </tr>
                </table>
                <table style={{width: '100%'}}>
                <thead>
                    <tr>
                    <td width="70%">
                        <table width="100%">
                        <tr>
                            <td style={{fontFamily: 'RobotoBold'}}>Invoice #</td>
                            <td>{invoice.number}</td>
                            <td dir="rtl"><span lang="ar" style={{fontFamily: 'TajawalBold', textAlign:'right'}}>رقم الفاتورة</span></td>
                        </tr>
                        <tr>
                            <td style={{fontFamily: 'RobotoBold'}}>Invoice ID</td>
                            <td>{invoice._id}</td>
                            <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>هوية الفاتورة</span></td>
                        </tr>
                        <tr>
                            <td style={{fontFamily: 'RobotoBold'}}>Issued On</td>
                            <td>{getDateOnly(invoice.issuedOn)}</td>
                            <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>تاريخ الفاتورة</span></td>
                        </tr>
                        <tr>
                            <td style={{fontFamily: 'RobotoBold'}}>Generated</td>
                            <td>{invoice.generatedOn}</td>
                            <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>تاريخ الاصدار</span></td>
                        </tr>
                        <tr>
                            <td style={{fontFamily: 'RobotoBold'}}>Customer</td>
                            {isItAllArabic(customer.name)?
                            <td lang="ar" style={{fontFamily: 'Tajawal'}}>{customer.name}</td>
                            :
                            <td>{customer.name}</td>
                            }
                            <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>العميل</span></td>
                        </tr>
                        
                        <tr>
                            <td style={{fontFamily: 'RobotoBold'}}>Email</td>
                            <td>{customer.email}</td>
                            <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>البريد الالكتروني</span></td>
                        </tr>
                        <tr>
                            <td style={{fontFamily: 'RobotoBold'}}>Mobile</td>
                            <td>{customer.mobile}</td>
                            <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>رقم الجوال</span></td>
                        </tr>
                        </table>
                    </td>
                    <td width="30%" style={{paddingLeft: '20px', verticalAlign:'top', textAlign:'right'}}>
                        <img src={qrCode} style={{maxWidth: "150px"}} /><br /><br />
                        <table style={{float: 'right'}}>
                        <tr>
                            <td style={pullLeft}><b>Total</b></td>
                            <td>{invoice.currency}</td>
                            <td>{getInternationalFormat(invoice.total_amount)}</td>
                        </tr>
                        <tr>
                            <td style={pullLeft}><b>Paid</b></td>
                            <td>{invoice.currency}</td>
                            <td>{(invoice&&getInternationalFormat(invoice.paid_amount)) || "0"}</td>
                        </tr>
                        <tr>
                            <td style={pullLeft}><b>Due</b></td>
                            <td>{invoice.currency}</td>
                            <td>{invoice&&invoice.paid_amount?getInternationalFormat((invoice.total_amount-invoice.paid_amount)):getInternationalFormat(invoice.total_amount)}</td>
                        </tr>
                        
                        </table>
                    </td>
                    </tr>
                    <tr>
                    
                    
                    </tr>
                </thead>
                </table>

                <Table style={{width: "100%", marginTop: "5em"}}>
            
            <TableBody>
                <TableRow style={{fontFamily: 'RobotoBold'}}>
                <TableCell>
                    Line Item
                </TableCell>
                <TableCell>
                    Amount
                </TableCell>
                <TableCell>
                    Discount
                </TableCell>
                <TableCell>
                    Net Amount
                </TableCell>
                {/* <TableCell>
                    VAT Amount
                </TableCell>
                <TableCell>
                    Total Amount
                </TableCell> */}
                </TableRow>
                {invoice&&invoice.line_items.map((obj, key) => (
                    <TableRow key={key}>
                    {isItAllArabic(obj.Name)?
                    <TableCell lang="ar" style={{fontFamily: 'Tajawal'}}>{obj.Name || ''}</TableCell>
                    :
                    <TableCell>{obj.Name || ''}</TableCell>
                    }
                    <TableCell>
                        {getInternationalFormat(obj.Amount) || 0}
                    </TableCell>
                    <TableCell>
                        {getInternationalFormat(obj.Discount) || 0}
                    </TableCell>
                    <TableCell>
                        {getInternationalFormat(obj.NetAmount) || 0}
                    </TableCell>
                    {/* <TableCell>
                        {obj.VatAmount || 0}
                    </TableCell>
                    <TableCell>
                        {obj.NetAmount&&obj.VatAmount?parseFloat(obj.VatAmount)+parseFloat(obj.NetAmount):0}
                    </TableCell> */}
                    </TableRow>
                ))}
            </TableBody>
            </Table>
            </div>
            <div>
                <table style={{marginTop:'100px', width: '100%'}}>
                    <tr>
                        <td style={{width: '50%', verticalAlign:'top', textAlign:'left'}}>
                        {isSister?<SisterCompanyBankInformation /> : <CompanyBankInformation />}
                        </td>
                        <td style={{width: '50%', verticalAlign:'top', textAlign:'right'}}>
                            {isSister?<img src="https://cacticonsult.com/assets/images/eststamp.png" style={{maxWidth: "150px"}} />:
                            <img src="https://cacticonsult.com/assets/images/cactistamp.png" style={{maxWidth: "150px"}} />}
                        </td>
                    </tr>
                </table>
            </div>
            </body>
        </html>
        )
    }

return (
    <Fragment>
    <Grid
        container
        spacing={3}
        direction="row"
        justify="flex-start"
        alignItems="center">
        {/* <Grid item>
        <IconButton
        aria-label="Grn"
        onClick={() => props.setSelectedInvoice(false)}>
        <CloseIcon />
        </IconButton>
        </Grid> */}
        <Grid item>
        <Typography component="h2" variant="h6" color="primary">
            {editInvoiceVisible?'Official Invoice':'Invoice Preview'}
        </Typography>
        </Grid>
        {editInvoiceVisible&&
            <Grid item xs={6}>
            <Button onClick={()=> downloadInvoicePdf()}>Download Pdf</Button>
            <Button onClick={()=> downloadInvoicePdfSister()}>Download Pdf (Est.)</Button>
            </Grid>
            
        }
    </Grid>

    <Card sx={{ minWidth: 500 }} >
    <CardContent>
        
        <Grid container justify="space-between" alignContent="center">
        <Grid item>
            {invoice&&invoice.QRCode?<QRCode id="invoiceQRCode" value={(invoice.QRCode.toString()) } /> : "No Qrcode"}
            <br />
            <Typography component="h2" variant="h6">
            <b>Total:</b> {invoice.currency} {(invoice&&invoice.total_amount&&getInternationalFormat(invoice.total_amount)) || 0}
            </Typography>
            <Typography component="h2" variant="h6">
            <b>Paid:</b> {invoice.currency} {(invoice&&invoice.paid_amount&&getInternationalFormat(invoice.paid_amount)) || 0}
            </Typography>
            <Typography component="h2" variant="h6">
            <b>Due:</b> {invoice.currency} {invoice&&invoice.total_amount&&invoice.paid_amount?getInternationalFormat((invoice.total_amount-invoice.paid_amount)):(invoice.total_amount&&getInternationalFormat(invoice.total_amount))||0}
            </Typography>
        </Grid>
        <Grid item>
        <Grid container direction="column">
            <Typography component="h2" variant="h4" color="primary">
            <b>Electronic Invoice</b>
            </Typography>
            <Typography component="h2" variant="h6">
            <b>Invoice ID:</b> {invoice._id}
            </Typography>
            <Typography component="h2" variant="h6">
            <b>Issued on:</b> {getDateOnly(invoice.issuedOn)}
            </Typography>
            <Typography component="h2" variant="h6">
            <b>Issue to:</b> {customer&&customer.name}
            </Typography>
        </Grid>
        </Grid>
        
        <Grid item>
        <Grid container direction="column">
            <Typography component="h2" variant="h6">
            <CompanyInformation />
            </Typography>
            
        </Grid>
        </Grid>
        
        </Grid>
        <Table>
        <TableHead>
            <TableRow>
            <TableCell>
                <b>Line Item</b>
            </TableCell>
            <TableCell>
                <b>Amount</b>
            </TableCell>
            <TableCell>
                <b>Discount</b>
            </TableCell>
            <TableCell>
                <b>Amount Due</b>
            </TableCell>
            {/*  <TableCell>
                <b>VAT Amount</b>
            </TableCell>
            <TableCell>
                <b>Amount Due</b>
            </TableCell> */}
            </TableRow>
        </TableHead>
        <TableBody>
            {invoice&&invoice.line_items?.map((obj, key) => (
                <TableRow key={key}>
                <TableCell>{obj.Name || ''}</TableCell>
                <TableCell>
                    {(obj.Amount&&getInternationalFormat(obj.Amount)) || 0}
                </TableCell>
                <TableCell>
                    {(obj.Discount&&getInternationalFormat(obj.Discount)) || 0}
                </TableCell>
                <TableCell>
                    {(obj.NetAmount&&getInternationalFormat(obj.NetAmount)) || 0}
                </TableCell>
                {/* <TableCell>
                    {obj.VatAmount || 0}
                </TableCell>
                <TableCell>
                    {obj.NetAmount&&obj.VatAmount?parseFloat(obj.VatAmount)+parseFloat(obj.NetAmount):0}
                </TableCell> */}
                </TableRow>
            ))}
        </TableBody>
        </Table>
        
    </CardContent>
    </Card>

</Fragment>
)
}

export default CustomerInvoicesView;