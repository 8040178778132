import React, { Fragment, useEffect } from 'react';
import { Buffer } from "buffer";
import {useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import { Close as CloseIcon}  from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TrashIcon from '@material-ui/icons/DeleteForever';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import Popover from '@material-ui/core/Popover';

import {Card, CardContent} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import QRCode from 'qrcode.react';
import ReactDOMServer from 'react-dom/server';
import FileDownload from 'js-file-download';

import {
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Tooltip,
  TextField,
  Button,
  setRef,
  LinearProgress,
  CircularProgress,
  MenuItem,
  Select,
  InputLabel
} from '@material-ui/core'

import { Router } from "@reach/router";

import {ApiFunctions} from "../config/api";

import {MainListItems} from './LeftNavView';
import CustomersView from "./CustomersView";
import { Add, AddCircleOutline, BlurCircularTwoTone, ContactsOutlined } from '@material-ui/icons';
import moment from 'moment';

import logoImage from "../imgs/logo.png";

var JSZip = require('jszip');
var zip = JSZip();

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://cacticonsult.com/">
        Cacti Software
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor:'#ffffff',
    color: '#000000'
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  notificationsContainer: {
    minWidth: '500px',
    maxWidth: "500px",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  notificationsRoot:{
    width: '100%',
    maxWidth: '55ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));


export default function Dashboard(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleNotifcationsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorEl(null);
  };

  const notificationsOpen = Boolean(anchorEl);
  const notificationsId = notificationsOpen ? 'simple-popover' : undefined;

  const [error, setError] = React.useState('');
  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;
  const propsUser = props.user;

  React.useEffect(()=>{

    isMounted.current = true;
    
    return () => {
        // executed when unmount
        isMounted.current = false;
    }

  },[propsUpdateUser, propsUser]);

  React.useEffect(()=>{
    setTimeout(()=>{
      if(isMounted.current){
        if(error !== ''){
          setError('');
        }
      }
    }, 3000);
  },[error]);


  return (
    <div className={classes.root}>
      <CssBaseline />
      
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Cacti Software
          </Typography> */}
          <Box className={classes.title}><img width={100} src={logoImage} /></Box>

          <Box>Actuary App V. 1.3.9</Box>

          {/* <IconButton color="inherit" onClick={handleNotifcationsClick}>
            
            <Badge badgeContent={null} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Popover
            id={notificationsId}
            open={notificationsOpen}
            anchorEl={anchorEl}
            onClose={handleNotificationsClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <NotificationsPane />
          </Popover> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems updateUserInformation={props.updateUserInformation} />
        </List>
        {/* <Divider />
        <List>{secondaryListItems}</List> */}
      </Drawer>

      {/*  Main View */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Router primary={false}>
          <MainDash path="/" updateUserInformation={props.updateUserInformation} user={props.user} error={error} setError={setError}  />
          <CustomersView path="/customers" updateUserInformation={props.updateUserInformation} user={props.user} error={error} setError={setError} />
        </Router>
      </main>
    </div>
  );
}

//hosts the main model projects view
function MainDash(props){
  
  const [invoiceMakerVisible, setInvoiceMakerVisible] = useState(false);
  const classes = useStyles();
  const setError = props.setError;
  const [projects, setProjects] = useState([]);

  const [loading, setLoading] = useState(false);

  const [refresh, setRefresh] = useState(true);

  const [newProjectName, setNewProjectName] = useState(null);
  const [newProjectNameKey, setNewProjectNameKey] = useState(Math.random());

  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(()=>{
      console.log("refreshing");
      //console.log(props.user);
      setLoading(true);
      ApiFunctions.getProjects(props.user).then((projects)=>{
        setProjects(projects);
        updateSelectedProject(projects);
      })
      .catch((e)=>setError('Your session might have expired. Please logout and log back in.'))
      .finally(()=>setLoading(false));
  }, [refresh]);
  
  const handleProjectDetails = (project) =>{
    setSelectedProject(project);
  }

  const handleAddProject = () => {
    if(newProjectName && newProjectName.length > 5){
      setLoading(true);
      ApiFunctions.addProject({
        name: newProjectName
      }, props.user).then((response)=>{
        setNewProjectName(null);
        setNewProjectNameKey(Math.random());
        setRefresh(!refresh);
      }).catch((error)=>{

      }).finally(()=>{
        setLoading(false);
      });
    }else{
      props.setError("Project Name must be at least 5 characters long");
    }
  }

  const updateSelectedProject = (projects) => {
    if(selectedProject){
      let foundProject = projects.find(p=>p._id===selectedProject._id);
      setSelectedProject(foundProject);
    }
  }

  const toggleInvoiceMaker = () =>{
    setInvoiceMakerVisible(prev=>!prev);
  }

  return(

    (invoiceMakerVisible?
    <InvoiceView toggleInvoiceMaker={toggleInvoiceMaker}/>
    :
    selectedProject? 
    <ProjectView 
    user={props.user} 
    selectedProject={selectedProject} 
    setSelectedProject={setSelectedProject} 
    error={props.error} 
    setError={props.setError} 
    refresh={refresh}
    setRefresh={setRefresh}
    />
    :
    <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={3}>
            {/* <Button onClick={()=>toggleInvoiceMaker()}>Invoice Maker</Button> */}
            {(props.error !== '') &&
            <Grid item xs={12}>
              <Alert style={{marginBottom: 10}} severity='error'>
                  {props.error}
              </Alert>
            </Grid>
            }
            
            {/* Important Information */}
            <Grid item xs={12}>
              <Typography component="h1" color="primary" variant ="h6" gutterBottom>
                  Add New Project 
              </Typography>
              <TextField
                    key={newProjectNameKey}
                    onChange={(e) => setNewProjectName(e.target.value.trim())}  
                    id="outlined-basic" 
                    label="Project Name" 
                    variant="outlined" 
                    fullWidth>
              </TextField>
              <Grid container spacing={3} style={{marginTop: 10}}                    
              direction="row"
              justify="flex-end"
              alignItems="center">
                <Grid item>
                  <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={(e)=>handleAddProject(e)}
                  disabled={loading?true:false}
                  > 
                    Add Project
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            
            <Grid item xs={12}>
              <Paper className={classes.paper}>
              <Typography component="h1" color="primary" variant ="h6" gutterBottom>
                  Current Projects 
              </Typography>
              {loading&& <LinearProgress />}
              <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                          Project Name
                        </TableCell>
                        <TableCell>
                          Added On
                        </TableCell>
                        <TableCell>
                          Analysis
                        </TableCell>
                        <TableCell align ="center">
                          Details
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {projects && projects.map((obj, key) => (
                    <TableRow key={key}>
                        <TableCell>
                            {obj.name}
                        </TableCell>
                        <TableCell>
                            {obj.addedOn}
                        </TableCell>
                        <TableCell>
                            {obj.analysis?obj.analysis.length:0}
                        </TableCell>
                        <TableCell align ="center">
                            <Tooltip 
                                title="Details" 
                                aria-label="Details"
                                >
                                <IconButton  
                                    variant="contained" 
                                    color="primary" 
                                    onClick={(e) => handleProjectDetails (obj)}>
                                    <DashboardIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
    )
  );
}

const ProjectView = (props) => {
  
  const classes = useStyles();
  const project = props.selectedProject;
  const setSelectedProject = props.setSelectedProject;
  const setError = props.setError;

  const defaultAnalysis = {
    turnover_range: "15%-10%"
  }
  const [newAnalysis, setNewAnalysis] = useState(defaultAnalysis);
  
  const [inputErrors, setInputErrors] = useState({});
  const [inputErrorsMsgs, setInputErrorsMsgs] = useState({});

  const setRefresh = props.setRefresh;
  const refresh = props.refresh;

  const [inputKey, setInputKey] = useState(null); 

  const [loading, setLoading] = useState(false);

  const [analysisProgress, setAnalysisProgress] = useState({});
  let currentAnalysis = null;

  const [resultsVisible, setResultsVisible] = useState({});

  const [newAnalysisForm, setNewAnalysisForm] = useState(false);

  const _uploadProgress = (event) =>{
    const percentFraction = event.loaded / event.total;
    const percent = Math.floor(percentFraction * 100);
    setAnalysisProgress[currentAnalysis._id] = percent;
    console.log(percent);
    if(percent >= 100)
      setAnalysisProgress(0);
  }

  const handleUploadAnalysis = (e, analysis) =>{
      if(e.target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
        currentAnalysis = analysis;
        console.log(e.target.files[0].name);
        const fd = new FormData();
        fd.append('employees', e.target.files[0], e.target.files[0].name);
        fd.append('_projectid', project._id);
        fd.append('_analysisid', analysis._id);
        ApiFunctions.uploadAnalysis(fd, props.user, _uploadProgress).then(res=>{
          //update the ui accordingly
          console.log(res);
          console.log('uploaded analysis');
          setInputKey(analysis._id+Math.random());
          setRefresh(!refresh);
        }).catch(e=>{
          setError("Could not upload the file at this time. Make sure your session is still active and try again.");
        })
      }else{
        setError("Only xlsx files are allowed.");
      }
  }

  const validDate = (date, required) => {
    //we are only interested in the first 10 charachters of this string
    if(!required && date.length===0) return true;
    //let checkedDate = date.split("T")[0];
    if (moment(date, "YYYY-MM-DD", true).isValid()) {
      return true;
    } else {
      return false;
    }
  }

  const validPositiveRate = (num, required) =>{
    console.log(num);
    if(!required && (num===null||num===undefined)) return true;
    if(isNaN(num)) return false;
    if(num<0.0001||num>0.5) return false;
    return /^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$/.test(num);
  }
  
  const validNewAnalysis = ()=>{
    let valid = true;
    if(!validPositiveRate(newAnalysis.ir, true)) {
      setInputErrors(errors=>({...errors, 'ir': true}));
      setInputErrorsMsgs(msgs=>({...msgs, 'ir': 'Must be a rate between 0.0001 and 0.5'}))
      valid=false;
    }
    else{
      setInputErrors(errors=>({...errors, 'ir':false}));
    }

    if(!validPositiveRate(newAnalysis.wgr, true)) {
      setInputErrors(errors=>({...errors, 'wgr': true}));
      setInputErrorsMsgs(msgs=>({...msgs, 'wgr': 'Must be a rate between 0.0001 and 0.5'}))
      valid=false;
    }
    else{
      setInputErrors(errors=>({...errors, 'wgr':false}));
    }

    if(!validPositiveRate(newAnalysis.resign_percent, true)) {
      setInputErrors(errors=>({...errors, 'resign_percent': true}));
      setInputErrorsMsgs(msgs=>({...msgs, 'resign_percent': 'Must be a rate between 0.0001 and 0.5'}))
      valid=false;
    }
    else{
      setInputErrors(errors=>({...errors, 'resign_percent':false}));
    }

    if(!validDate(newAnalysis.ending, true)) {
      setInputErrors(errors=>({...errors, 'ending': true}));
      setInputErrorsMsgs(msgs=>({...msgs, 'ending': 'Must be a valid yyyy-mm-dd formatted date'}))
      valid=false;
    }
    else{
      setInputErrors(errors=>({...errors, 'ending':false}));
    }

    return valid;
  }

  const handleAddAnalysis = () =>{
    if(!validNewAnalysis()){
      return null;
    }
    
    ApiFunctions.addAnalysis(project, newAnalysis, props.user).then((res)=>{
      setRefresh(!refresh);
      setNewAnalysisForm(!newAnalysisForm);
    })
    .catch((e)=>console.error(e))
    .finally(()=>setLoading(false));
  }

  const handleTextChange = (e, field, float) =>{
    setNewAnalysis({...newAnalysis, [field]: float? parseFloat(e.target.value.trim()): e.target.value.trim() })
  }

  const handleSelectTurnover = (e) =>{
    setNewAnalysis({...newAnalysis, turnover_range: e.target.value});
  }

  const handleRemoveAnalysis = (analysis) =>{
    //console.log(analysis);
    setLoading(true);
    ApiFunctions.removeAnalysis(project, analysis, props.user).then((res)=>{
      console.log(res);
      setRefresh(!refresh);
    }).catch((e)=>{
      //console.log("Error response:", e.response?.status, e.response);
      (e.response?.status===403)?setError('No authorization. Only for admins.')
                :
      setError('Your session might have expired. Please logout and log back in.')
    })
    .finally(()=>setLoading(false));
  }

  const handleRunProject = () => {
    setLoading(true);
    ApiFunctions.runProject(project, props.user).then((res)=>{
      console.log(res);
      setTimeout(setRefresh(!refresh), 2000);
    }).catch((e)=>console.error(e))
    .finally(()=>setLoading(false));
  }

  const handleRunAnalysis = (_analysisid) => {

    setLoading(true);
    ApiFunctions.runAnalysis(project, _analysisid, props.user).then((res)=>{
      //console.log(res);
      setTimeout(()=>setRefresh(!refresh), 5000);
    }).catch((e)=>{
      console.error(e);
      setError("There is an issue with the employee data preventing the analysis to run. Make sure all data are typed correctly and try again.")
    })
    .finally(()=>setTimeout(()=>setLoading(false),7000));
  }
  
  function toggleResults(_analysisid){
    setResultsVisible((results)=>({...results, [_analysisid]: !results[_analysisid]}));
  }

  const handleExportSummary = (analysis) => {
    const fileName = analysis.ending+"-output-"+analysis.ir+"-"+analysis.wgr;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(analysis.summary);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const handleExportInvoice = (invoice)=>{

  }

  const handleDownloadTimelines = (analysis)=>{
    setLoading(true);
    ApiFunctions.downloadTimelines(project, analysis._id, props.user).then((res)=>{
      exportTimelines(res,analysis);
    }).catch((e)=>{
      console.error(e);
      (e.response?.status===403)?setError('No authorization. Only for admins.')
      :setError("There is an issue with getting the timelines. Please try again later.")
    })
    .finally(()=>setTimeout(()=>setLoading(false),2000));
  }

  const exportTimelines = (timelineItems,analysis) => {
    analysis.employees.map((e)=>{
      const employeetm = timelineItems.filter((item)=>item.employee_code===e.code)
      let timelineObjects = [];
      employeetm.map((tm)=>{
        timelineObjects.push(tm.timeLineObject);
      })
      const fileName = analysis.ending+"-"+e.code+"-timeline-"+analysis.ir+"-"+analysis.wgr;
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const ws = XLSX.utils.json_to_sheet(timelineObjects);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      zip.file(fileName+fileExtension,data);
    });

    zip.generateAsync({type: "blob"})
      .then(content => {
          FileSaver.saveAs(content, analysis.ending+"-"+analysis.ir+"-"+analysis.wgr+'timelines.zip'); 
    });
  }
  
  const handleNewAnalysisForm = () =>{
    setNewAnalysisForm(!newAnalysisForm);
    setNewAnalysis(defaultAnalysis);
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={3}>
            <Tooltip 
                title="Back" 
                aria-label="Back"
                >
                <IconButton  
                    variant="contained" 
                    color="primary" 
                    onClick={(e) => setSelectedProject (null)}>
                    <ArrowBackIosIcon />
                </IconButton>
            </Tooltip>
           {/*  <Tooltip 
                title="Run Project" 
                aria-label="Run Project"
                >
                <IconButton  
                    variant="contained" 
                    color="primary" 
                    onClick={(e) => handleRunProject()}>
                    <PlayCircleFilledIcon />
                </IconButton>
              </Tooltip> */}
              
            {(props.error !== '') &&
            <Grid item xs={12}>
              <Alert style={{marginBottom: 10}} severity='error'>
                  {props.error}
              </Alert>
            </Grid>
            }
            
            <Grid item xs={12}>
              <Paper className={classes.paper}>
              <Typography component="h1" color="primary" variant ="h6" gutterBottom>
                  Project Details: {project&&project.name}
              </Typography>
              <Grid item>
              <Tooltip 
                title="Add New Analysis" 
                aria-label="Add New Analysis"
                >
                <IconButton  
                    variant="contained" 
                    color="primary" 
                    onClick={(e) => handleNewAnalysisForm()}>
                    <AddCircleOutline />
                </IconButton>
              </Tooltip>
              </Grid>
              
              
              {newAnalysisForm&&
              <React.Fragment>
              <Grid container spacing={3}> 
              <Grid item xs={6}>  
                  <TextField
                    onChange={(e) => handleTextChange(e, 'ending', false)}
                    error={inputErrors['ending']?true:false}
                    helperText={inputErrors['ending']?inputErrorsMsgs['ending']:""}
                    id="outlined-basic" 
                    label="Value Date (yyyy-mm-dd)" 
                    variant="outlined"
                    fullWidth>
                  </TextField>
              </Grid>
              <Grid item xs={6}>
                  <Select 
                  id="turnover" 
                  variant="outlined"
                  defaultValue={"15%-10%"}
                  /* value={newAnalysis.turnover_range} */
                  fullWidth
                  onChange = {(e)=>handleSelectTurnover(e)}> 
                      <MenuItem value="15%-10%">15%-10% Turnover</MenuItem>
                      <MenuItem value="20%-10%">20%-10% Turnover</MenuItem>
                      <MenuItem value="25%-15%">25%-15% Turnover</MenuItem>
                      <MenuItem value="30%-20%">30%-20% Turnover</MenuItem>
                    </Select>
              </Grid>
              <Grid item xs={6}>  
                  <TextField 
                    onChange={(e) => handleTextChange(e, 'ir', true)}
                    error={inputErrors['ir']?true:false}
                    helperText={inputErrors['ir']?inputErrorsMsgs['ir']:""}
                    id="outlined-basic" 
                    label="Interest Rate" 
                    variant="outlined" 
                    fullWidth>
                  </TextField>
              </Grid>
              <Grid item xs={6}>  
                  <TextField 
                    onChange={(e) => handleTextChange(e, 'wgr', true)}
                    error={inputErrors['wgr']?true:false}
                    helperText={inputErrors['wgr']?inputErrorsMsgs['wgr']:""}
                    id="outlined-basic" 
                    label="Wage Growth Rate" 
                    variant="outlined" 
                    fullWidth>
                  </TextField>
              </Grid>
              <Grid item xs={6}>
                  <TextField 
                    onChange={(e) => handleTextChange(e, 'resign_percent', true)}
                    error={inputErrors['resign_percent']?true:false}
                    helperText={inputErrors['resign_percent']?inputErrorsMsgs['resign_percent']:""}
                    id="outlined-basic" 
                    label="Resign Percent" 
                    variant="outlined" 
                    fullWidth>
                  </TextField>
              </Grid>
             
            </Grid>
            <Grid container spacing={3} style={{marginTop: 10}}                    
              direction="row"
              justify="flex-end"
              alignItems="center">
                <Grid item>
                  <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={(e)=>handleAddAnalysis(e)}
                  disabled={loading?true:false}
                  > 
                    Add Analysis
                  </Button>
                </Grid>
            </Grid>
            </React.Fragment>
              }
              {loading&&
                <Box sx={{ width: '100%' }}>
                  <Typography>Some Operations are Data Intensive and might take up to 3 minutes to complete.</Typography>
                  <LinearProgress />
                </Box>
              }
              <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                          Value Date
                        </TableCell>
                        <TableCell>
                          Turnover
                        </TableCell>
                        <TableCell>
                          IR
                        </TableCell>
                        <TableCell>
                          WGR
                        </TableCell>
                        <TableCell>
                          Resign
                        </TableCell>
                        
                        <TableCell>
                          Employees
                        </TableCell>
                        <TableCell align="center">
                          Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {project && project.analysis && project.analysis.map((obj, key) => (
                      <React.Fragment  key={key}>
                    <TableRow>
                        <TableCell>
                            {obj.ending}
                        </TableCell>
                        <TableCell>
                            {obj.turnover_range}
                        </TableCell>
                        <TableCell>
                            {obj.ir}
                        </TableCell>
                        <TableCell>
                            {obj.wgr}
                        </TableCell>
                        <TableCell>
                            {obj.resign_percent}
                        </TableCell>
                       
                        <TableCell>
                          {(analysisProgress[obj._id]>0) && 
                          <CircularProgress variant="determinate" size={20} value={analysisProgress[obj._id]} />}
                        <input
                          key={inputKey?inputKey:obj._id}
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          style={{display:'none'}}
                          id={obj._id+'cbf'}
                          type="file"
                          onChange={(e)=>handleUploadAnalysis(e, obj)}
                        />
                            <label htmlFor={obj._id+'cbf'}>
                            <Button style={{padding:5, borderRadius: 5, border:1}} component="span" aria-label="upload employees">
                            {obj.employees.length} Employees
                            </Button>
                            </label>
                        </TableCell>
                        <TableCell align ="center">
                            <Tooltip 
                              title="Run Analysis" 
                              aria-label="Run Analysis"
                              >
                              <IconButton
                                  variant="contained" 
                                  color="primary" 
                                  disabled={loading?true:false}
                                  onClick={(e) => handleRunAnalysis(obj._id)}>
                                  <PlayCircleFilledIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip 
                              title="View Results" 
                              aria-label="View Results"
                              >
                              <IconButton  
                                  variant="contained" 
                                  color="primary" 
                                  onClick={(e) => toggleResults(obj._id)}>
                                  <EqualizerIcon />
                              </IconButton>
                            </Tooltip>
                            
                            <Tooltip 
                              title="Download Summary" 
                              aria-label="Download Summary"
                              >
                              <IconButton  
                                  variant="contained" 
                                  color="primary" 
                                  disabled={loading?true:false}
                                  onClick={(e) => handleExportSummary(obj)}>
                                  <CloudDownloadIcon />
                              </IconButton>
                            </Tooltip>

                            <Tooltip 
                              title="Download Timelines" 
                              aria-label="Download Timelines"
                              >
                              <IconButton  
                                  variant="contained" 
                                  color="primary" 
                                  disabled={loading?true:false}
                                  onClick={(e) => handleDownloadTimelines(obj)}>
                                  <CloudDownloadIcon />
                              </IconButton>
                            </Tooltip>

                            <Tooltip 
                                title="Remove Forever" 
                                aria-label="Remove Forever"
                                >
                                <IconButton  
                                    variant="contained" 
                                    color="primary" 
                                    disabled={loading?true:false}
                                    onClick={(e) => handleRemoveAnalysis(obj)}>
                                    <TrashIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                            <TableCell colSpan={7}>
                              {resultsVisible[obj._id]&&
                              <React.Fragment>
                                <Typography>
                                  <strong>Liability</strong>: {obj.results&&obj.results['liability']&&obj.results['liability']}
                                </Typography>
                                <Typography>
                                  <strong>Service Cost</strong>: {obj.results&&obj.results['serviceCost']&&obj.results['serviceCost']}
                                </Typography>
                                {obj.resultErrors&&obj.resultErrors.length>0&&obj.resultErrors.map((err, key)=>(
                                  <Typography key={key}>
                                  <strong>ERROR</strong>: {err}
                                  </Typography>
                                ))
                                }
                                {obj.resultErrors&&obj.resultErrors.length===0&&
                                <Typography>
                                <strong>No Errors Found</strong>
                                </Typography>
                                }
                              </React.Fragment>
                              }
                            </TableCell>
                    </TableRow>
                    </React.Fragment>
                    ))}
                </TableBody>
            </Table>
              </Paper>
            </Grid>
           
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
  )
}

const InvoiceView = (props) =>{
  const classes = useStyles();
  const [invoice, setInvoice] = useState({});
  const [currentLineItem, setCurrentLineItem] = useState({});
  
  function roundAmount(amount){
    
    let frac = 100;
    return Math.round((amount+Number.EPSILON)*frac) / frac;
  }
  const handleTextInput = (e, fieldname) =>{
    setInvoice({...invoice, [fieldname]: e.target.value.trim()});
  }
  const handleNumberInput = (e,fieldname)=>{
    setInvoice({...invoice, [fieldname]: roundAmount(parseFloat(e.target.value.trim()))});
  }
  const handleLineItemAdd = () =>{
    let tempInvoice = {...invoice};
    let lineitem = {...currentLineItem};
    tempInvoice.LineItems?tempInvoice.LineItems.push(lineitem):(tempInvoice.LineItems =[lineitem]);
    setInvoice(tempInvoice);
    updateInvoiceTotal(tempInvoice);
  }
  const handleLineItemPop = () =>{
    let tempInvoice = {...invoice};
    //let lineitem = {...currentLineItem};
    tempInvoice.LineItems&&tempInvoice.LineItems.length>0&&tempInvoice.LineItems.pop();
    //console.log(tempInvoice);
    setInvoice(tempInvoice);
    updateInvoiceTotal(tempInvoice);
  }
  const handleLiTextInput = (e, fieldname) =>{
    setCurrentLineItem({...currentLineItem, [fieldname]: e.target.value.trim()});
  }
  const handleLiNumberInput = (e,fieldname)=>{
    setCurrentLineItem({...currentLineItem, [fieldname]: roundAmount(parseFloat(e.target.value.trim()))});
  }

  const updateInvoiceTotal = (tempInvoice) =>{
    let total = 0;
    //let tempInvoice = {...invoice};
    tempInvoice.LineItems&&tempInvoice.LineItems.map(item=>{
      total+=(item.NetAmount)?item.NetAmount:0;
    });
    tempInvoice.TotalAmountWithVat = roundAmount(total);
    tempInvoice.QRCode = getTlvBuffer(tempInvoice);
    setInvoice(tempInvoice);
  }

  useEffect(()=>{
    const totalAmount = currentLineItem.Amount?roundAmount((currentLineItem.Amount - (currentLineItem.Discount || 0))):0;
    setCurrentLineItem({...currentLineItem, ['NetAmount']: totalAmount});
  }, [currentLineItem.Amount, currentLineItem.Discount]);

  const companyName = "Cacti Software W.L.L";
  const vatNumber = "1234567777";

  function getTlvBuffer(invoice){
    //base64 string of tlv - hex tag + hex length + string value - SellerName(1) - SellerVatNumber(2) - InvoiceTimeStamp(3) - InvoiceTotalWithVat(4) - VatTotal(5) - XMLHash(6) - 7 and 8 crypto",
    
    const invoiceTimeStamp = new Date(invoice.issuedOn);
    let tlvs = [
        Buffer.from(('0000'+(1..toString(16))).slice(-4), "hex"),
        Buffer.from(('0000'+(companyName.length.toString(16))).slice(-4), "hex"),
        Buffer.from(companyName),
        Buffer.from(('0000'+(2..toString(16))).slice(-4), "hex"),
        Buffer.from(('0000'+(vatNumber.length.toString(16))).slice(-4), "hex"),
        Buffer.from(vatNumber),
        Buffer.from(('0000'+(3..toString(16))).slice(-4), "hex"),
        Buffer.from(('0000'+(invoiceTimeStamp.toString().length.toString(16))).slice(-4), "hex"),
        Buffer.from(invoiceTimeStamp.toString()),
        Buffer.from(('0000'+(4..toString(16))).slice(-4), "hex"),
        Buffer.from(('0000'+(invoice.TotalAmountWithVat.toString().length.toString(16))).slice(-4), "hex"),
        Buffer.from(invoice.TotalAmountWithVat.toString()),
        Buffer.from(('0000'+(5..toString(16))).slice(-4), "hex"),
        Buffer.from(('0000'+(0..toString(16))).slice(-4), "hex"),
        Buffer.from(0..toString())
        /* Buffer.from(invoice.VatCategoryTaxAmount.TaxAmount.length.toString(16), "hex"),
        Buffer.from(invoice.VatCategoryTaxAmount.TaxAmount) */
    ];
    return Buffer.concat([...tlvs]);

  }



  return(
    <Fragment>
      
    <Container maxWidth="xl" className={classes.container}>
    <Grid
        container
        spacing={3}
        direction="row"
        justify="flex-start"
        alignItems="center">
        <Grid item>
        <IconButton
          aria-label="Grn"
          onClick={() => props.toggleInvoiceMaker()}>
          <CloseIcon />
        </IconButton>
        </Grid>
        </Grid>

    <Typography component="h2" variant="h6" color="primary" gutterBottom>Invoice Information</Typography>
    <Grid container spacing={3}>
      <Grid item xs={6} lg={4} xl={3}>
        <TextField
          onChange={(e) => handleTextInput(e, 'customerName')}  
          id="outlined-basic" 
          label="Customer Name" 
          variant="outlined" 
          fullWidth>
          </TextField>
      </Grid>
      <Grid item xs={6} lg={4} xl={3}>
        <TextField
          onChange={(e) => handleTextInput(e, 'customerEmail')}  
          id="outlined-basic" 
          label="Customer Email" 
          variant="outlined" 
          fullWidth>
          </TextField>
      </Grid>
      <Grid item xs={6} lg={4} xl={3}>
        <TextField
          onChange={(e) => handleTextInput(e, 'customerMobile')}  
          id="outlined-basic" 
          label="Customer Mobile" 
          variant="outlined" 
          fullWidth>
          </TextField>
      </Grid>
      <Grid item xs={6} lg={4} xl={3}>
        <TextField
          onChange={(e) => handleTextInput(e, 'issuedOn')}  
          id="outlined-basic" 
          label="Invoice Date" 
          variant="outlined" 
          fullWidth>
          </TextField>
      </Grid>
      <Grid item xs={6} lg={4} xl={3}>
        <TextField
          onChange={(e) => handleTextInput(e, 'Number')}  
          id="outlined-basic" 
          label="Invoice Number" 
          variant="outlined" 
          fullWidth>
          </TextField>
      </Grid>
      <Grid item xs={6} lg={4} xl={3}>
        <TextField
          onChange={(e) => handleTextInput(e, 'ID')}  
          id="outlined-basic" 
          label="Invoice ID" 
          variant="outlined" 
          fullWidth>
          </TextField>
      </Grid>
      <Grid item xs={6} lg={4} xl={3}>
        <TextField
          onChange={(e) => handleTextInput(e, 'Currency')}  
          id="outlined-basic" 
          label="Invoice Currency" 
          variant="outlined" 
          fullWidth>
          </TextField>
      </Grid>
      <Grid item xs={6} lg={4} xl={3}>
        <TextField
          onChange={(e) => handleNumberInput(e, 'paid_amount')}  
          id="outlined-basic" 
          label="Paid Amount" 
          variant="outlined" 
          fullWidth>
          </TextField>
      </Grid>
    </Grid>
    <Typography style={{marginTop: 10}} component="h2" variant="h6" color="primary" gutterBottom>Add Line Item</Typography>
    <Grid container spacing={3}>
      <Grid item xs={4} lg={3} xl={2}>
        <TextField
          onChange={(e) => handleLiTextInput(e, 'Name')}  
          id="outlined-basic" 
          label="Item Name" 
          variant="outlined" 
          fullWidth>
          </TextField>
      </Grid>
      <Grid item xs={4} lg={3} xl={2}>
        <TextField
          onChange={(e) => handleLiNumberInput(e, 'Amount')}  
          id="outlined-basic" 
          label="Amount" 
          variant="outlined" 
          fullWidth>
          </TextField>
      </Grid>
      <Grid item xs={4} lg={3} xl={2}>
        <TextField
          onChange={(e) => handleLiNumberInput(e, 'Discount')}  
          id="outlined-basic" 
          label="Discount" 
          variant="outlined" 
          fullWidth>
          </TextField>
      </Grid>
      <Grid item xs={4} lg={3} xl={2}>
        <TextField
          value={currentLineItem.NetAmount || 0}
          onChange={(e) => handleLiNumberInput(e, 'NetAmount')}  
          disabled
          id="outlined-basic" 
          label="Net Amount" 
          variant="outlined" 
          fullWidth>
          </TextField>
      </Grid>
    </Grid>
    <Grid container spacing={3}>
      <Grid item>
        <Button onClick={()=>handleLineItemAdd()}>Add Line Item</Button>
      </Grid>
      <Grid item>
        <Button onClick={()=>handleLineItemPop()}>Pop Line Item</Button>
      </Grid>
      
    </Grid>
      <OfficialInvoiceView invoice={invoice}/>
    </Container>
    </Fragment>
    
  )
}

const OfficialInvoiceView = (props) =>{  
  const invoice = props.invoice;

  function getInternationalFormat(amount){
    return Intl.NumberFormat().format(amount);
  }
  
  const CompanyInformation = () =>{
    return (
      <div>
        Cacti Software W.L.L<br />
        CR 149570-1<br />
        Office 51 Bldg 1301 Rd. 4026 Blk. 340<br />
        Tel +973 3929 0099 <br />
        Manama, Kingdom of Bahrain
      </div>
    )
  }
  
  const downloadInvoicePdf = () => {
    const canvas = document.getElementById("invoiceQRCode");
    const dataUrl = canvas.toDataURL("img/png");
    let htmlInvoice = ReactDOMServer.renderToString(<HtmlComponent qrCode={dataUrl}/>);
    ApiFunctions.downloadInvoice(htmlInvoice).then((res)=>{
      FileDownload(res,invoice.issuedOn+".pdf");
    }).catch((e)=>{
      console.log(e);
    });
  }

  const HtmlComponent = (props) =>{
    const qrCode = props.qrCode;
    const invoiceContainer = {
      marginTop: "20pt",
    }
    const pullLeft = {
      textAlign: 'left'
    }
    return (
      <html>
        <head>
          <title>KSA E-Invoice</title>
        </head>
        <body style={{fontFamily: "Roboto",/*  fontSize: "12pt" */}}>
          <div style={invoiceContainer}>
            <table style={{marginBottom: '40px', width: '100%'}}>
              <tr>
                <td width="50%"><img src="https://cacticonsult.com/assets/images/logo-small.png" style={{maxWidth: "100px"}} /></td>
                <td width="50%" style={{textAlign: 'right'}}>
                 <CompanyInformation />
                </td>
              </tr>
            </table>
            <table style={{width: '100%'}}>
              <thead>
                <tr>
                  <td width="70%">
                    <table width="100%">
                      <tr>
                        <td style={{fontFamily: 'RobotoBold'}}>Invoice #</td>
                        <td>{invoice.Number}</td>
                        <td dir="rtl"><span lang="ar" style={{fontFamily: 'TajawalBold', textAlign:'right'}}>رقم الفاتورة</span></td>
                      </tr>
                      <tr>
                        <td style={{fontFamily: 'RobotoBold'}}>Invoice ID</td>
                        <td>{invoice.ID}</td>
                        <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>هوية الفاتورة</span></td>
                      </tr>
                      <tr>
                        <td style={{fontFamily: 'RobotoBold'}}>Created</td>
                        <td>{invoice.issuedOn}</td>
                        <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>التاريخ و الوقت</span></td>
                      </tr>
                      <tr>
                        <td style={{fontFamily: 'RobotoBold'}}>Customer</td>
                        <td>{invoice.customerName}</td>
                        <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>العميل</span></td>
                      </tr>
                      
                      <tr>
                        <td style={{fontFamily: 'RobotoBold'}}>Email</td>
                        <td>{invoice.customerEmail}</td>
                        <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>البريد الالكتروني</span></td>
                      </tr>
                      <tr>
                        <td style={{fontFamily: 'RobotoBold'}}>Mobile</td>
                        <td>{invoice.customerMobile}</td>
                        <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>رقم الجوال</span></td>
                      </tr>
                    </table>
                  </td>
                  <td width="30%" style={{paddingLeft: '20px', verticalAlign:'top', textAlign:'right'}}>
                    <img src={qrCode} style={{maxWidth: "150px"}} /><br /><br />
                    <table style={{float: 'right'}}>
                      <tr>
                        <td style={pullLeft}>Total</td>
                        <td>{invoice.Currency}</td>
                        <td>{getInternationalFormat(invoice.TotalAmountWithVat)}</td>
                      </tr>
                      <tr>
                        <td style={pullLeft}>Paid</td>
                        <td>{invoice.Currency}</td>
                        <td>{(invoice&&getInternationalFormat(invoice.paid_amount)) || "0"}</td>
                      </tr>
                      <tr>
                        <td style={pullLeft}>Due</td>
                        <td>{invoice.Currency}</td>
                        <td>{invoice&&invoice.paid_amount?getInternationalFormat((invoice.TotalAmountWithVat-invoice.paid_amount)):getInternationalFormat(invoice.TotalAmountWithVat)}</td>
                      </tr>
                      
                    </table>
                  </td>
                </tr>
                <tr>
                 
                 
                </tr>
              </thead>
            </table>

            <Table style={{width: "100%", marginTop: "5em"}}>
          
          <TableBody>
            <TableRow style={{fontFamily: 'RobotoBold'}}>
              <TableCell>
                Line Item
              </TableCell>
              <TableCell>
                Amount
              </TableCell>
              <TableCell>
                Discount
              </TableCell>
              <TableCell>
                Net Amount
              </TableCell>
              {/* <TableCell>
                VAT Amount
              </TableCell>
              <TableCell>
                Total Amount
              </TableCell> */}
            </TableRow>
            {invoice&&invoice.LineItems.map((obj, key) => (
                <TableRow key={key}>
                  <TableCell>{obj.Name || ''}</TableCell>
                  <TableCell>
                    {getInternationalFormat(obj.Amount) || 0}
                  </TableCell>
                  <TableCell>
                    {getInternationalFormat(obj.Discount) || 0}
                  </TableCell>
                  <TableCell>
                    {getInternationalFormat(obj.NetAmount) || 0}
                  </TableCell>
                  {/* <TableCell>
                    {obj.VatAmount || 0}
                  </TableCell>
                  <TableCell>
                    {obj.NetAmount&&obj.VatAmount?parseFloat(obj.VatAmount)+parseFloat(obj.NetAmount):0}
                  </TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
          </div>
        </body>
      </html>
    )
  }

  return (
    <Fragment>
    <Grid
        container
        spacing={3}
        direction="row"
        justify="flex-start"
        alignItems="center">
        {/* <Grid item>
        <IconButton
          aria-label="Grn"
          onClick={() => props.setSelectedInvoice(false)}>
          <CloseIcon />
        </IconButton>
        </Grid> */}
        <Grid item>
          <Typography component="h2" variant="h6" color="primary">
            Invoice
          </Typography>
        </Grid>
       <Grid item xs={6}>
          <Button onClick={()=> downloadInvoicePdf()}>Download Pdf</Button>
       </Grid>
      </Grid>

      <Card sx={{ minWidth: 500 }} >
      <CardContent>
        
        <Grid container justify="space-between" alignContent="center">
          <Grid item>
            {invoice&&invoice.QRCode?<QRCode id="invoiceQRCode" value={(invoice.QRCode.toString()) } /> : "No Qrcode"}
            <br />
            <Typography component="h2" variant="h6">
             <b>Total:</b> {(invoice&&invoice.TotalAmountWithVat&&getInternationalFormat(invoice.TotalAmountWithVat)) || 0}
            </Typography>
            <Typography component="h2" variant="h6">
             <b>Paid:</b> {(invoice&&invoice.paid_amount&&getInternationalFormat(invoice.paid_amount)) || 0}
            </Typography>
            <Typography component="h2" variant="h6">
             <b>Due:</b> {invoice&&invoice.TotalAmountWithVat&&invoice.paid_amount?getInternationalFormat((invoice.TotalAmountWithVat-invoice.paid_amount)):(invoice.TotalAmountWithVat&&getInternationalFormat(invoice.TotalAmountWithVat))||0}
            </Typography>
          </Grid>
          <Grid item>
          <Grid container direction="column">
            <Typography component="h2" variant="h4" color="primary">
              <b>Electronic Invoice</b>
            </Typography>
            <Typography component="h2" variant="h6" color="primary">
             <b>Invoice #/ID:</b> {invoice.Number} / {invoice.ID}
            </Typography>
            <Typography component="h2" variant="h6" color="primary">
             <b>Issued on:</b> {invoice.issuedOn}
            </Typography>
            <Typography component="h2" variant="h6">
             <b>Issue to:</b> {invoice&&invoice.customerName}
            </Typography>
          </Grid>
          </Grid>
          
          <Grid item>
          <Grid container direction="column">
            <Typography component="h2" variant="h6">
             <CompanyInformation />
            </Typography>
            
          </Grid>
          </Grid>
         
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Line Item</b>
              </TableCell>
              <TableCell>
                <b>Amount</b>
              </TableCell>
              <TableCell>
                <b>Discount</b>
              </TableCell>
              <TableCell>
                <b>Amount Due</b>
              </TableCell>
             {/*  <TableCell>
                <b>VAT Amount</b>
              </TableCell>
              <TableCell>
                <b>Amount Due</b>
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice&&invoice.LineItems?.map((obj, key) => (
                <TableRow key={key}>
                  <TableCell>{obj.Name || ''}</TableCell>
                  <TableCell>
                    {(obj.Amount&&getInternationalFormat(obj.Amount)) || 0}
                  </TableCell>
                  <TableCell>
                    {(obj.Discount&&getInternationalFormat(obj.Discount)) || 0}
                  </TableCell>
                  <TableCell>
                    {(obj.NetAmount&&getInternationalFormat(obj.NetAmount)) || 0}
                  </TableCell>
                  {/* <TableCell>
                    {obj.VatAmount || 0}
                  </TableCell>
                  <TableCell>
                    {obj.NetAmount&&obj.VatAmount?parseFloat(obj.VatAmount)+parseFloat(obj.NetAmount):0}
                  </TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </CardContent>
      </Card>

  </Fragment>
  )
}

function NotificationsPane(props){

  const classes = useStyles();

  return(
    <Container className={classes.notificationsContainer}>
      <List className={classes.notificationsRoot}>
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText
            primary="Beta Release 1"
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  Notifications are disabled at the moment
                </Typography>
                {" — You will start receiving notifications here."}
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
      </List>

    </Container>
  );

}