import React from 'react';
import {
  Button,
  CssBaseline,
  Paper,
  Grid,
  Typography,
  TextField
} from '@material-ui/core'

import {ApiFunctions} from "../config/api";


import { makeStyles } from '@material-ui/core/styles';
import data from '../config/data';

import splash from '../imgs/splash.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    /* backgroundImage: 'url(https://source.unsplash.com/random)', */
    backgroundImage: "url(" + splash + ")",
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  }
}));

export default function SignInSide(props) {
  const classes = useStyles();

  const [accessCode, setAccessCode] = React.useState(null);
  const [codeError, setCodeError] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const handleCodeChange = (e) =>{
    setAccessCode(parseFloat(e.target.value.trim()));
  }

  const signInEnterKey = (e) =>{
    if (/* ev.ctrlKey && */ e.key === 'Enter') {
      e.preventDefault();
      signInUser()
    }
  }

  const signIn = (
    <Grid container component="main" className={classes.root}>
    <CssBaseline />
    <Grid item xs={false} sm={4} md={7} className={classes.image} />
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          You seem to be signed out, sign in?
        </Typography>
        <form className={classes.form} noValidate>
          <TextField 
            onChange={(e) => handleCodeChange(e)}
            error={codeError?true:false}
            helperText={codeError?'Please Enter a Valid Code':''}
            id="outlined-basic" 
            label="Access Code" 
            variant="outlined"
            disabled={loading?true:false}
            onKeyPress={(e) => { signInEnterKey(e);}}
            fullWidth>
          </TextField>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={()=>signInUser()}
            disabled={loading?true:false}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Grid>
  </Grid>
  );

  const signInUser = () =>{
    //const SampleUser = data.sample_user;
    //let userData = SampleUser;
    if(validCode()){
      setCodeError(false);
      setLoading(true);
      ApiFunctions.getUserData(accessCode).then((userData)=>{
        props.updateUserInformation(userData);
      }).catch((e)=>{
        setCodeError(true);
      }).finally(()=>{
        setLoading(false);
      });
    }else{
      setCodeError(true);
    }
    
  };

  function validCode(){
    if(accessCode&& !isNaN(accessCode)) return true;
    else return false;
  }

  return (
    <div>
    {signIn}
    </div>
  );
}
