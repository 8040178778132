import React from 'react';
import data from '../config/data';
import CustomerView from './CustomerView';
import FloatingActionButton from './FAB';
import Alert from '@material-ui/lab/Alert';
import { 
    Grid, 
    Container, 
    LinearProgress, 
    CircularProgress,
    Tooltip, 
    Button, 
    IconButton, 
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Select,
    MenuItem,
} from '@material-ui/core';

import {Edit as EditIcon, Receipt as InvoicesIcon} from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';

import { ApiFunctions } from '../config/api';
import CustomerInvoicesView from './CustomerInvoicesView';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      marginTop:theme.spacing(3)
    },
    fixedHeight: {
      height: 240,
    },
}));

const CustomersView = (props) => {
    const classes = useStyles();
    const [newCustomerVisible, setNewCustomerVisible] = React.useState(false);
    const [editCustomerVisible, setEditCustomerVisible] = React.useState(false);
    const [customerInvoicesVisible, setCustomerInvoicesVisible] = React.useState(false);
    const [selectedCustomer, setSelectedCustomer] = React.useState(null);
    const [customers, setCustomers] = React.useState([]);
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState('');
    const [refresh, setRefresh] = React.useState(new Date());

    const isMounted = React.useRef(null);
    
    React.useEffect(()=>{
        setTimeout(()=>{
          if(isMounted.current){
            if(error !== ''){
              setError('');
            }
          }
        }, 3000);
      },[error]);

    React.useEffect(()=>{
        console.log("refreshing");
        setLoading(true);
        ApiFunctions.getCustomers(props.user).then((customers)=>{
          setCustomers(customers);
        }).
        catch(
            (e)=>{
                (e.response?.status===403)?setError('No authorization. Only for admins.')
                :
                setError('Your session might have expired. Please logout and log back in.')
            }
        ).finally(()=>{
            setLoading(false);
        });
    }, [refresh]);

    const addNewCustomer = (newCustomer) =>{
        setLoading(true);
        ApiFunctions.addCustomer(newCustomer, props.user).then((res)=>{
            let addedCustomer = {...newCustomer, _id: res._id};
            let tempArr = [...customers];
            tempArr.push(addedCustomer);
            setCustomers(tempArr);
        })
        .catch((e)=>setError('Your session might have expired or an error occured. Try to logout and log back in.'))
        .finally(()=>{
            setLoading(false); 
            setNewCustomerVisible(false);
        });
    }

    const editCustomer = (customer) =>{
        setLoading(true);
        ApiFunctions.editCustomer(customer, props.user).then((res)=>{
            let tempArr = [...customers];
            let foundIndex = tempArr.findIndex(c=>c._id===customer._id);
            tempArr[foundIndex] = customer;
            setCustomers(tempArr);
        }).catch((e)=>setError('Your session might have expired or an error occured. Try to logout and log back in.'))
        .finally(()=>{
            setLoading(false); 
            setEditCustomerVisible(false);
        });
        
    }

    const handleAddCustomerClick = (e) => {
        newCustomerVisible ? setNewCustomerVisible(false) : setNewCustomerVisible(true);
    }

    const handleEditCustomerClick = (e, customer) => {
        setEditCustomerVisible(true);
        setSelectedCustomer(customer);
    }

    const handleCustomerInvoicesClick = (e, customer) => {
        setCustomerInvoicesVisible(true);
        setSelectedCustomer(customer);
    }

    return (
                          

        <Container maxWidth="xl" className={classes.container}>

                {(error !== '') &&
                <Grid item xs={12}>
                <Alert style={{marginBottom: 10}} severity='error'>
                    {error}
                </Alert>
                </Grid>
                }
            
                {editCustomerVisible ?
                <CustomerView
                    loading={loading}
                    editCustomerVisible={editCustomerVisible}
                    setEditCustomerVisible={setEditCustomerVisible}
                    selectedCustomer={selectedCustomer}
                    editCustomer={editCustomer}
                />
                :
                customerInvoicesVisible?
                    <CustomerInvoicesView user={props.user} customer={selectedCustomer} setCustomerInvoicesVisible={setCustomerInvoicesVisible} />
                :

                <React.Fragment>
                    <Grid 
                    container 
                    spacing={3}
                    
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                        <Grid item>
                            <Typography component="h2" variant="h6" color="primary" >List of Customers</Typography>
                        </Grid>
                        <Grid item>
                            <FloatingActionButton
                                variant="contained"
                                color="primary"
                                clickHandler={handleAddCustomerClick} />
                        </Grid>
                    </Grid>
                    {newCustomerVisible && 
                    <CustomerView 
                        loading={loading}
                        newCustomerVisible={newCustomerVisible}
                        addNewCustomer={addNewCustomer}
                        setNewCustomerVisible={setNewCustomerVisible}
                        />
                    }
                    <Grid item xs={12}>
                    <Paper className={classes.paper}>
                    {loading&&<LinearProgress />}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Name</b></TableCell>
                                <TableCell><b>Email</b> </TableCell>
                                <TableCell><b>Mobile</b></TableCell>
                                <TableCell><b>Industry</b></TableCell>
                                <TableCell><b>Action</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customers && customers.map((obj, key) => (
                                <TableRow key={key}>
                                    <TableCell>{obj.name}</TableCell>
                                    <TableCell>{obj.email}</TableCell>
                                    <TableCell>{obj.mobile}</TableCell>
                                    <TableCell>{obj.industry}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Edit Customer" aria-label="edit">
                                            <IconButton variant="contained" color="primary" onClick={
                                                (e) => handleEditCustomerClick(e, obj)}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Customer Invoices" aria-label="edit">
                                            <IconButton variant="contained" color="primary" onClick={
                                                (e) => handleCustomerInvoicesClick(e, obj)}>
                                                <InvoicesIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    </Paper>
                    </Grid>
                </React.Fragment>
            }
        </Container>
    )
};
export default CustomersView;